import React from "react";
// import Navbar from "../../navbars/Navbar";
import Footer from "./footer";
import Section1 from "./section1/index";
import Section2 from "./section2/index";
import Section3 from "./section3/index";
import Section4 from "./section4/index";
import Section5 from "./section5/index";
import Section6 from "./section6/index";
// import Section7 from "./section7/index";
// import Section8 from "./section8/index";

const HomePage = () => {
  window.scrollTo(0, 0);

  return (
    <div className="relative">
      {/* <Navbar/> */}
     <Section1/>
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      {/* <Section7 /> */}
      {/* <Section8 /> */}
      <Footer/>
    </div>
  );
};

export default HomePage;
