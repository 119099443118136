import React from "react";
// import { Link } from "react-router-dom";
import { ReactComponent as FacebookIcon } from "../../assets/FacebookIcon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/InstagramIcon.svg";
import Logo from "../../assets/Logo.png";
import { ReactComponent as MailIcon } from "../../assets/MailIcon.svg";
import { ReactComponent as MenuIcon } from "../../assets/MenuIcon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/PhoneIcon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/TwitterIcon.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/YoutubeIcon.svg";
import {
  Button,
  ContactInfo,
  Contacts,
  Container,
  HeaderLinks,
  LogoContainer,
  MainHeaderContainer,
  MenuIconContainer,
  SocialIcon,
  Socials,
} from "../../components/main/homePage/header/header.Elements";

// interface propsType {
//   setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
// }

const Navbar = () => {
  return (
    <Container style={{width:'90%',margin:'auto'}}>
      <Contacts>
        <ContactInfo>
          <MailIcon />
          <p>abc@gmail.com</p>
          <PhoneIcon />
          <p>1234567890</p>
        </ContactInfo>
        <Socials>
          <SocialIcon>
            <FacebookIcon />
          </SocialIcon>
          <SocialIcon>
            <InstagramIcon />
          </SocialIcon>
          <SocialIcon>
            <TwitterIcon />
          </SocialIcon>
          <SocialIcon>
            <YoutubeIcon />
          </SocialIcon>
        </Socials>
      </Contacts>
      <MainHeaderContainer>
        <MenuIconContainer >
          <MenuIcon />
        </MenuIconContainer>
        <HeaderLinks>
          <a href="https://subspace.money/about-us">about</a>
          <a href="https://subspace.money/contact">contact</a>
        </HeaderLinks>
        <LogoContainer>
          <img src={Logo} alt="Company Logo" />
          <p style={{marginLeft:'9px'}}>IMPULSE</p>
        </LogoContainer>
        <a  href="https://play.google.com/store/apps/details?id=org.grow90.whatsub">
        <Button style={{background:'#514fb2'}}>Download</Button>
        </a>
      </MainHeaderContainer>
    </Container>
  );
};

export default Navbar;
