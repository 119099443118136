import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;
export const ContentContainer = styled.div`
  width: 80%;
  padding: 5rem 0;
  display: flex;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  @media screen and (max-width: 425px) {
    flex-direction: column;
    gap: 3rem;
  }
  & > div:first-child {
    width: 50%;
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
  & > div:first-child > h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  & > div:first-child > p {
    width: 60%;
    line-height: 24px;
    color: #6c6c72;
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      height: 70px;
      overflow: hidden;
    }
  }
  & > div:last-child {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & img {
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
`;
export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 3rem;
  @media screen and (max-width: 425px) {
    & > a {
      max-width: 40%;
      & img {
        width: 100%;
      }
    }
  }
`;
export const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
`;
export const Stats = styled.div`
  background-color: var(--primary-color);
  width: 30%;
  /* height: 10rem; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 20px; */
  & > svg {
    margin: 20px;
    height: 20%;
  }
  & p {
    text-transform: uppercase;
    color: white;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 10px;
  }
  & p:nth-child(2) {
    font-weight: bold;
    @media screen and (max-width: 1024px) {
      font-size: 1.3rem;
    }
  }
  & p:last-child {
    @media screen and (max-width: 1024px) {
      font-size: 0.8rem;
    }
  }
`;
