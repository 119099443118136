import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import EditorJS from "react-editor-js";
import {
  FaFacebookF,
  FaHeart,
  FaLinkedin,
  FaRegHeart,
  FaTwitter,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { LIKE_BLOG, UNLIKE_BLOG } from "../../graphql/mutation";
import { FETCH_BLOG } from "../../graphql/query";
import { blog } from "../../Interface";
const CodeTool = require("@editorjs/code");
const Paragraph = require("@editorjs/paragraph");
// const AttachesTool = require("@editorjs/attaches");
// const Personality = require("@editorjs/personality");
const LinkTool = require("@editorjs/link");
const Warning = require("@editorjs/warning");
const Table = require("@editorjs/table");
const Delimiter = require("@editorjs/delimiter");
const Quote = require("@editorjs/quote");
const SimpleImage = require("@editorjs/simple-image");
const Marker = require("@editorjs/marker");
const InlineCode = require("@editorjs/inline-code");
const Header = require("@editorjs/header");
const FootnotesTune = require("@editorjs/footnotes");
// const ImageTool = require("@editorjs/image");
const Underline = require("@editorjs/underline");
const RawTool = require("@editorjs/raw");
const Checklist = require("@editorjs/checklist");
const List = require("@editorjs/list");
const Embed = require("@editorjs/embed");
const LinkAutocomplete = require("@editorjs/link-autocomplete");
// const NestedList = require("@editorjs/nested-list");
const TextVariantTune = require("@editorjs/text-variant-tune");
const Alert = require("editorjs-alert");
const AlignmentTuneTool = require("editorjs-text-alignment-blocktune");

interface propsType {
  browserId: string;
}

const INITIAL_DATA = {
  blocks: [],
};
const EDITORJS_TOOLS = {
  code: CodeTool,
  paragraph: Paragraph,
  linkTool: LinkTool,
  warning: Warning,
  table: Table,
  delimiter: Delimiter,
  quote: Quote,
  Marker: Marker,
  inlineCode: InlineCode,
  header: Header,
  footnotes: FootnotesTune,
  image: SimpleImage,
  underline: Underline,
  raw: RawTool,
  checklist: Checklist,
  embed: Embed,
  link: LinkAutocomplete,
  list: List,
  textVariant: TextVariantTune,
  alert: Alert,
  anyTuneName: AlignmentTuneTool,
};
const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const StyledDiv = styled.div`
  & h1 {
    font-size: 2rem;
    font-weight: bold;
  }
  & h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  & h3 {
    font-size: 1.17rem;
    font-weight: bold;
  }
  & h4 {
    font-size: 1rem;
    font-weight: bold;
  }
  & h5 {
    font-size: 0.83rem;
    font-weight: bold;
  }
  & h6 {
    font-size: 0.67rem;
    font-weight: bold;
  }
  & .codex-editor__redactor {
    padding-bottom: 2rem !important;
  }
`;

const Blog = ({ browserId }: propsType) => {
  const [editorData, setEditorData] = useState(INITIAL_DATA);
  // console.log(editorData);
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const { url } = useParams<{ url: string }>();
  console.log(url);
  const { loading, data } = useQuery<{ getTopic: blog }>(FETCH_BLOG, {
    variables: {
      url: url,
      browser_id: browserId,
    },
    fetchPolicy: "no-cache",
  });


  console.log(data?.getTopic);
  // console.log(FETCH_BLOG);
  const [mutateLike] = useMutation(LIKE_BLOG, {
    variables: {
      blog_id: data?.getTopic.id,
      browser_id: browserId,
    },
  });
  const [mutateUnLike] = useMutation(UNLIKE_BLOG, {
    variables: {
      blog_id: data?.getTopic.id,
      browser_id: browserId,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      let blogData = JSON.parse(data.getTopic.body);

      setEditorData({ blocks: blogData });
      setIsLiked(data.getTopic.isLiked);
      setLikeCount(data.getTopic.likes);
    }
  }, [loading, data]);

  const getTime = () => {
    if (data) {
      let time = new Date(data.getTopic.updated_at);
      return `${MONTHS[time.getMonth()]} ${time.getDate()}`;
    } else {
      return "";
    }
  };
  const getLikesCount = () => {
    if (likeCount <= 999) return `${likeCount}`;
    else if (likeCount <= 999999) return `${(likeCount / 1000).toFixed(2)}K`;
    else return `${(likeCount / 1000000).toFixed(2)}M`;
  };

  const handleLike = () => {
    mutateLike();
    setIsLiked(true);
    setLikeCount(likeCount + 1);
  };
  const handleUnLike = () => {
    mutateUnLike();
    setIsLiked(false);
    setLikeCount(likeCount - 1);
  };

  return (
    <>
      <StyledDiv className="w-11/12 p-6 mx-auto my-16 bg-white rounded-lg xl:w-4/5 md:my-32">
        <div className="mx-auto max-w-40">
          <p className="text-left text-4xl md:text-5xl font-bold">{data?.getTopic.title}</p>
          <div className="flex items-center justify-between mt-4 mb-4">
            <div className="flex items-center justify-center gap-2">
              <div className="flex items-center justify-center w-12 h-12 overflow-hidden rounded-full">
                <img
                  className="w-full"
                  src={data?.getTopic.dp}
                  alt="profile"
                />
              </div>
              <div>
                <p className="text-base text-darkBlue">
                  {data?.getTopic.fullname}
                </p>
                {data && <p className="text-xs">{getTime()}</p>}
              </div>
            </div>
            <div className="flex gap-1">
              <div
                className="flex items-center justify-center p-1 overflow-hidden rounded-full bg-darkBlue"
                data-href={window.location.pathname}
              >
                <a
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                    window.location.href
                  )}&amp;src=sdkpreparse`}
                  className="flex items-center justify-center fb-xfbml-parse-ignore"
                  rel="noreferrer"
                >
                  <FaFacebookF className="w-4/5 text-white h-4/5" />
                </a>
              </div>
              <a
                href={`https://twitter.com/intent/tweet?text=${encodeURI(
                  window.location.href
                )}`}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center p-1 overflow-hidden rounded-full bg-darkBlue"
              >
                <FaTwitter className="text-white" />
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
                  window.location.href
                )}`}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center p-1 overflow-hidden rounded-full bg-darkBlue"
              >
                <FaLinkedin className="text-white" />
              </a>
            </div>
          </div>
        </div>
        <EditorJS
          data={editorData}
          tools={EDITORJS_TOOLS}
          autofocus
          readOnly
          enableReInitialize
        />
        <div className="flex items-center justify-between mx-auto max-w-40">
          <div>
            <div className="flex items-center justify-center gap-2 p-1 overflow-hidden rounded-full">
              {isLiked ? (
                <FaHeart
                  className="cursor-pointer text-darkBlue"
                  onClick={handleUnLike}
                />
              ) : (
                <FaRegHeart
                  className="cursor-pointer text-darkBlue"
                  onClick={handleLike}
                />
              )}
              <p>{getLikesCount()}</p>
            </div>
          </div>
          <div className="flex gap-1">
            <div
              className="flex items-center justify-center p-1 overflow-hidden rounded-full bg-darkBlue"
              data-href={window.location.pathname}
            >
              <a
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                  window.location.href
                )}&amp;src=sdkpreparse`}
                className="flex items-center justify-center fb-xfbml-parse-ignore"
                rel="noreferrer"
              >
                <FaFacebookF className="w-4/5 text-white h-4/5" />
              </a>
            </div>
            <a
              href={`https://twitter.com/intent/tweet?text=${encodeURI(
                window.location.href
              )}`}
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center p-1 overflow-hidden rounded-full bg-darkBlue"
            >
              <FaTwitter className="text-white" />
            </a>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center p-1 overflow-hidden rounded-full bg-darkBlue"
            >
              <FaLinkedin className="text-white" />
            </a>
          </div>
        </div>
      </StyledDiv>
    </>
  );
};

export default Blog;
