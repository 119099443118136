import React, {useState} from "react";
import { Link } from "react-router-dom";
import {FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const[email, setEmail] = useState('');
  const[password, setPassword] = useState('');

  const[show, setShow] = useState(false);

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
  }

  return (
    <>
      <p className="text-xl font-medium text-center text-black">Login to Dashboard </p>
      <p className="text-sm text-center md:hidden my-2">
        New to Subspace ?{" "}
        <Link to="/auth/signup" className="text-blue-600 no-underline font-bold">Sign Up</Link>
      </p>

      <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-0 mt-4">
        
        <input
          className="w-full p-2 border-b-2 border-gray-300 outline-none text-md"
          type="email"
          placeholder="Your Email"
          name="email"
          id="email"
          value={email}
          onChange={(e) =>setEmail(e.target.value)}
        />

        <div className="w-full border-b-2 border-gray-300 flex flex-row">
          <input required
            className="w-9/10 p-2 outline-none text-md"
            type={`${show ? "text" : "password"}`}
            placeholder="Password"
            name="password"
            id="password"
            value={password}
            onChange={(e) =>setPassword(e.target.value)}
          />
          <button type="button" onClick={()=>setShow(!show)}
            className="w-1/10 flex items-center justify-center outline focus:outline-none">
            <p className="text-gray-500">{show ? <FaEyeSlash /> : <FaEye />}</p>
          </button>
        </div>

        <button type="button"
          className="text-blue-500 text-sm flex justify-end outline-none focus:outline-none">
            Forgot Password ?
        </button>

        <button type="submit"
          className="w-1/2 p-2 mt-2 mx-auto text-white bg-blue-600 hover:bg-blue-800 rounded-sm">
          Log In
        </button>
      </form>
    </>
  );
};

export default Login;
