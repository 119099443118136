import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CompanyLogo } from "../../assets/logoSmall.svg";
import search from '../../assets/icons/search.png';
import more from '../../assets/icons/more.png';
import bell from '../../assets/icons/bell.png';
import avatar from '../../assets/icons/avatar.png';
import hamBurger from '../../assets/icons/hamBurger.png';

interface propsType {
    isDrawerOpen: boolean;
    setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardNavbar = ({isDrawerOpen, setIsDrawerOpen}: propsType)=> {
    return (
        <div className="bg-white py-3 px-4 md:px-8 flex flex-row justify-between items-center shadow-dashboardNav">
            <div onClick={() => setIsDrawerOpen(true)}
                className="flex cursor-pointer transition items-center justify-center lg:hidden">
                <img src={hamBurger} alt="menu" className="h-full w-full"/>
            </div>
            <Link to="/" className="lg:hidden">
                <CompanyLogo className="h-8 text-black" />
            </Link>
            <div className="text-2xl font-medium hidden lg:block">Dashboard</div>
            <div className="flex flex-row gap-4 justify-between items-center">
                <img src={search} alt="search" className="hidden md:block"/> 
                <img src={more} alt="more" className="hidden md:block"/>
                <div className="bg-blue-600 rounded-xl h-6 w-auto py-1 px-3 md:flex flex-row gap-1 items-center hidden">
                    <img src={bell} alt="bell" />
                    <span className="text-sm text-bold text-white">16</span>
                </div>
                <div className="flex flex-row gap-2 items-center">
                    <img src={avatar} alt="avatar" />
                    <span className="hidden md:block">Grow90</span>
                </div>
            </div>
        </div>
    )
}

export default DashboardNavbar;