import React, {useState} from 'react';
import{ Switch, Route, Redirect } from 'react-router-dom';
import DashboardSidebar from '../sidebars/DashboardSidebar';
import Home from '../dashboard/Home/Home';
import Account from '../dashboard/Account/Account';
import Customers from '../dashboard/Customers/Customers';
import Disputes from '../dashboard/Disputes';
import Offers from '../dashboard/Offers';
import Refunds from '../dashboard/Refunds';
import Rewards from '../dashboard/Rewards';
import Settings from '../dashboard/Settings';
import Settlement from '../dashboard/Settlement';
import Subscription from '../dashboard/Subscription';
import Transactions from '../dashboard/Transactions';
import DashboardNavbar from '../navbars/DashboardNavbar';

function DashboardLayout() {
    const[isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <> 
            <div className="bg-dashboardBg  min-h-screen w-full lg:flex lg:flex-row">

                <div  className={` md:w-300 bg-navigationBg  lg:h-auto flex flex-col transition ease-linear duration-200 transform: ;
                    absolute top-0 left-0 lg:relative h-screen w-2/3  z-10
                ${isDrawerOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0"}`}>
                    <DashboardSidebar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                </div>
                
                <div className="w-full">
                    <nav>
                        <DashboardNavbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}/>
                    </nav>
                    <Switch>
                        <Route path="/dashboard/home" component={Home} />
                        <Route path="/dashboard/transactions" component={Transactions} />
                        <Route path="/dashboard/subscription" component={Subscription} />
                        <Route path="/dashboard/settlement" component={Settlement} />
                        <Route path="/dashboard/disputes" component={Disputes} />
                        <Route path="/dashboard/refunds" component={Refunds} />
                        <Route path="/dashboard/customers" component={Customers} />
                        <Route path="/dashboard/offers" component={Offers} />
                        <Route path="/dashboard/rewards" component={Rewards} />
                        <Route path="/dashboard/account" component={Account} />
                        <Route path="/dashboard/settings" component={Settings} />
                        <Redirect from="*" to="/dashboard/home" />
                    </Switch>
            </div>
            </div>
        </>
    )
}

export default DashboardLayout;
