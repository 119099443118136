import React from 'react';

const Offers = ()=> {
    return (
        <div className="text-blue-900">
            Offers
        </div>
    )
}

export default Offers;