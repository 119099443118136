import {gql} from "@apollo/client";

export const FETCH_BLOGS = gql`
  query getSections($id: uuid!, $title: String!) {
    getSections(request: { id: $id, title: $title }) {
      created_at
      id
      image_url
      section_title
      title
      url
    }
    getSectionClasses(request: { id: $id }) {
      title
    }
  }
`;

export const FETCH_BLOG = gql`
  query getCourseXTopics($url: String!, $browser_id: String!) {
    getTopic(request: { url: $url, browser_id: $browser_id }) {
      id
      body
      created_at
      updated_at
      fullname
      dp
      title
      likes
      isLiked
    }
  }
`;

export const FETCH_MEMBERS = gql`
query getTeamDetails {
  our_team(order_by: {order: asc}) {
    name
    designation
    image
  }
}
`;

export const FETCH_SEARCH_ITEMS = gql`
  query getSearchResultPublic($query: String!) {
    getSearchResultPublic(request: {query: $query}) {
      id
      image_url
      service_name
    }
  }
`;