import React from "react";
import store1 from "../../../assets/store/store1.png";
import store2 from "../../../assets/store/store2.png";
import store3 from "../../../assets/store/store3.png";
import store4 from "../../../assets/store/store4.png";
import greenTick from "../../../assets/greenTick.png";
import { StyledImage } from "../../commons";

const Section3 = () => {
  const classes = {
    outerDiv : "w-full flex flex-col items-center gap-4 md:gap-8",
    h2 : "text-2xl md:text-4xl font-bold",
    innerDiv : "w-11/12 lg:w-4/5 flex flex-col md:flex-row items-center gap-4 md:gap-0 justify-between",
    ul : "flex flex-col gap-4 list-disc text-left w-300",
    li : "flex items-center gap-4 text-sm md:text-base"
  }
  
  return (
    <section className="text-center p-2 flex flex-col items-center gap-16 my-24 text-white w-11/12 lg:w-4/5 mx-auto">
      <div className={classes.outerDiv}>
        <h2 className={classes.h2}>
          One-stop solution for all types of subscriptions
        </h2>
        <div className={classes.innerDiv}>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Accept multiple types of payment with ease.
            </li>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Create Subscription and coupon with just a single click
            </li>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Get multiple Business insights.
            </li>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Get all the benefits of the marketplace.
            </li>
          </ul>
          <div className="w-300 h-300">
            <StyledImage src={store1} alt="store 1" className="w-full h-full" />
          </div>
        </div>
      </div>

      <div className={classes.outerDiv}>
        <h2 className={classes.h2}>
          Seamless integration with the subscription ecosystem
        </h2>
        <div className={classes.innerDiv}>
          <div className="w-300 h-300">
            <StyledImage src={store2} alt="store 2" className="w-full h-full"/>
          </div>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Run attractive promotional offers
            </li>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Partner with SubSpace to run attractive, cashback reward points for your users
            </li>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Run offer for all your devices
            </li>
          </ul>
        </div>
      </div>
      
      <div className={classes.outerDiv}>
        <h2 className={classes.h2}>
          Professional reconciliation platform
        </h2>
        <div className={classes.innerDiv}>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Get all the analytics at your fingertips.
            </li>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Single click settlements and refunds
            </li>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Multiple KPI to choose from
            </li>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              View Bank Settlements
            </li>
          </ul>
          <div className="w-300 h-300">
            <StyledImage src={store3} alt="store 3" className="w-full h-full"/>
          </div>
        </div>
      </div>

      <div className={classes.outerDiv}>
        <h2 className={classes.h2}>
          Automatic reminder post and pre-billing cycle
        </h2>
        <div className={classes.innerDiv}>
          <div className="w-300 h-300">
            <StyledImage src={store4} alt="store 4" className="w-full h-full"/>
          </div>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              By default, every user gets mail and SMS notifications before the subscription gets over
            </li>
            <li className={classes.li}>
              <img src={greenTick} alt="green tick" />
              Businesses can create a manual reminder cycle.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Section3;
