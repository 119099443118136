import React from "react";
import AppFeaturesbg from "../../../../assets/AppFeaturesbg.png";
import AppShowcase002 from "../../../../assets/AppShowcase002.png";
// import { ReactComponent as ChartIcon } from "../../assets/ChartIcon.svg";
// import { ReactComponent as ConsumerSupportIcon } from "../../assets/ConsumerSupportIcon.svg";

import Camera from '../../../../assets/camera.png'
import Graph from '../../../../assets/graph.png'
import Tv from '../../../../assets/tv.png'
import Board from '../../../../assets/board.png'
import FacebookIcon from '../../../../assets/FacebookIcon.png'
import { ReactComponent as MobileIcon } from "../../../../assets/MobileIcon.svg";
// import { ReactComponent as RetinaIcon } from "../../assets/RetinaIcon.svg";

import { Container, ContentContainer, FeaturesCard } from "./section3.Elements";

const Section3 = () => {
  return (
    <Container>
      <img src={AppFeaturesbg} alt="App Fretures background" />
      <ContentContainer>
        <div>
          {/* <SectionTitle isBlack={false}>Get the IMUPLSE Classes advantage</SectionTitle> */}
          {/* <SectionSubTitle isBlack={false}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
            nunc ante velit vitae. Est tellus vitae, nullam lobortis enim.
            Faucibus amet etiam tincidunt rhoncus, ullamcorper velit.
            Ullamcorper risus tempor, ac nunc libero urna, feugiat.
          </SectionSubTitle> */}
        </div>
        <div>
          <FeaturesCard pos="center">
            
            <h3>Get the IMUPLSE Classes advantage</h3>
            
          </FeaturesCard>
        </div>
        <div>
          <div>
            <FeaturesCard pos="right">
              <img style={{marginBottom:'1.5rem'}} src={FacebookIcon} alt="" />
              <h3>BEST TEACHERS</h3>
              <p style={{marginBottom:'1.2rem'}}>Best Teachers from IITs & top tier colleges with 10+ of experience</p>
            </FeaturesCard>
            <FeaturesCard pos="right">
              <img style={{marginBottom:'1.5rem'}} src={Board} alt="" />
              <h3>Mock Tests</h3>
              <p style={{marginBottom:'1.2rem'}}>Prepare better for your exam, learn to manage your time and handle the pressure better.
              Improve, prepare effectively and score better.</p>
            </FeaturesCard>
            <FeaturesCard pos="right">
            <img style={{marginBottom:'1.5rem'}} src={Tv} alt="" />
              <h3>Live and interactive</h3>
              <p style={{marginBottom:'1.2rem'}}> Interaction between student & teacher, better than recorded videos</p>
            </FeaturesCard>
          </div>
          <div>
            <img style={{width:'98%',margin:'auto',marginRight:'-7%'}} src={AppShowcase002} alt="App Features" />
          </div>
          <div>
            <FeaturesCard pos="left">
              <MobileIcon />
              <h3>aDAPTIVE TEACHING</h3>
              <p style={{marginBottom:'1.2rem'}}>Delivering customized learning based on the student's learning pace</p>
            </FeaturesCard>

            <FeaturesCard pos="left">
            <img style={{marginBottom:'1.5rem'}} src={Graph} alt="" />
              <h3>Performance Analysis</h3>
              <p style={{marginBottom:'1.2rem'}}>Examine your strengths and weakness in a particular subject </p>
            </FeaturesCard>

            <FeaturesCard pos="left">
            <img style={{marginBottom:'1.5rem'}} src={Camera} alt="" />
              <h3>Doubt?</h3>
              <p style={{marginBottom:'1.2rem'}}>Capture. Crop. Get Physics, Chemistry, Math, Biology
                Solutions all in one place</p>
            </FeaturesCard>
          </div>
        </div>
        {/* <div>
          <FeaturesCard pos="center">
            <ConsumerSupportIcon />
            <h3>24/7 support by real pepole</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </FeaturesCard>
        </div> */}
      </ContentContainer>
    </Container>
  );
};

export default Section3;
