import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 425px) {
    height: auto;
  }
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
    height: 100%;
  }
`;
export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 80%;
  padding: 5rem 0;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  & > div:last-child {
    max-width: 30%;
    @media screen and (max-width: 425px) {
      max-width: 100%;
    }
    & > img {
      width: 100%;
    }
  }
  @media screen and (max-width: 425px) {
    flex-direction: column;
    padding: 5rem 0;
    height: auto;
    gap: 5rem;
  }
`;
export const ContentDiv = styled.div`
  background-color: white;
  padding: 9px 30px;
  width: 40%;
  position: relative;
  @media screen and (max-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-width: 425px) {
    width: 95%;
    align-self: flex-start;
    padding: 30px 20px;
  }
  &::after {
    content: "";
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 120%;
    border: 18px solid #7572ff;
    z-index: -1;
    @media screen and (max-width: 1024px) {
      left: 2%;
      height: 110%;
    }
    @media screen and (max-width: 425px) {
      left: 1%;
      height: 105%;
      border: 10px solid #7572ff;
    }
  }
  & h2 {
    color: #5956e9;
    font-weight: bold;
    font-size: 3rem;
    line-height: 63px;
    text-transform: uppercase;
    margin-bottom: 10px;
    @media screen and (max-width: 1024px) {
      font-size: 2.5rem;
    }
  }
  & p:first-of-type {
    font-size: 1rem;
    line-height: 24px;
    color: #6c6c72;
    margin-bottom: 30px;
  }
  & p:last-of-type {
    font-size: 1.4rem;
    line-height: 37px;
    color: #232233;
    text-transform: uppercase;
  }
`;
export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 425px) {
    & > a {
      max-width: 40%;
      & > img {
        width: 100%;
      }
    }
  }
`;
