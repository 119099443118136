import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignUp from '../auth/SignUp';
import Login from '../auth/Login';
import AuthNavbar from '../navbars/AuthNavbar';
import Advertisement from "../auth/Advertisement";

function AuthLayout() {
    return (
        <div>
            <nav className="w-11/12 lg:w-4/5 mx-auto">
                <AuthNavbar />
            </nav>
            <div className="w-11/12 lg:w-4/5 xl:w-3/5 mx-auto my-16 bg-white overflow-hidden rounded-md flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 h-auto shadow-auth z-10 py-5 md:py-16 px-5 lg:px-10">
                    <Switch>
                        <Route path="/auth/signup"><SignUp /></Route>
                        <Route path="/auth/login"><Login /></Route>
                        <Redirect from="*" to="/auth/login" />
                    </Switch>
                </div>
                <div className="w-full md:w-1/2 px-2 py-8 h-full bg-offWhite">
                    <Advertisement />
                </div>
            </div>
        </div>
    )
}

export default AuthLayout;
