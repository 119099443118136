import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as CompanyLogo } from "../../assets/companyLogo.svg";
import home from "../../assets/icons/home.png";
import transactions from "../../assets/icons/transactions.png";
import subscription from "../../assets/icons/subscription.png";
import settlement from "../../assets/icons/settlement.png";
import disputes from "../../assets/icons/disputes.png";
import refunds from "../../assets/icons/refunds.png";
import customers from "../../assets/icons/customers.png";
import offers from "../../assets/icons/offers.png";
import rewards from "../../assets/icons/rewards.png";
import account from "../../assets/icons/account.png";
import settings from "../../assets/icons/settings.png";

interface propsType {
    isDrawerOpen: boolean;
    setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const List = [
    {
        name: 'Home',
        icon: home,
        route: '/dashboard/home'
    },
    {
        name: 'Transactions',
        icon: transactions,
        route: '/dashboard/transactions'
    },
    {
        name: 'Subscription',
        icon: subscription,
        route: '/dashboard/subscription'
    },
    {
        name: 'Settlement',
        icon: settlement,
        route: '/dashboard/settlement'
    },
    {
        name: 'Disputes',
        icon: disputes,
        route: '/dashboard/disputes'
    },
    {
        name: 'Refunds',
        icon: refunds,
        route: '/dashboard/refunds'
    },
    {
        name: 'Customers',
        icon: customers,
        route: '/dashboard/customers'
    },
    {
        name: 'Offers',
        icon: offers,
        route: '/dashboard/offers'
    },
    {
        name: 'Rewards',
        icon: rewards,
        route: '/dashboard/rewards'
    },
    {
        name: 'Account',
        icon: account,
        route: '/dashboard/account'
    },
    {
        name: 'Settings',
        icon: settings,
        route: '/dashboard/settings'
    }
]

const DashboardSidebar = ({isDrawerOpen, setIsDrawerOpen}: propsType) => {
    const[active, setActive] = useState('home');
    const location = useLocation();

    useEffect(() => {
        const active = location.pathname.split('/')[2];
        setActive(active);  
        console.log(active);     
    }, [location])

    const selectActive = (name: string) =>{
        setActive(name);
        console.log(name);
        if(isDrawerOpen){
            setIsDrawerOpen(false);
        }        
    }

    return (
        <>  
            <button className="absolute text-lg text-white top-4 right-4 lg:hidden"
                onClick={() => setIsDrawerOpen(false)}
            >
                &#9932;
            </button>
            <Link to="/" className="hidden lg:block">
                <CompanyLogo className="h-12  m-8" />
            </Link>
            <div className="text-2xl text-white mb-8 font-medium lg:hidden">Dashboard</div>
            {List.map((item, index) => {
                return(
                    <Link to={item.route} key={index} className={active===item.name.toLowerCase()? "text-white bg-dashboardLinkBg border-l-4 border-borderCl p-3 px-4" : "text-sidebarText hover:text-hoverCl p-3 px-5"}
                        onClick={()=>selectActive(item.name)}>
                        <div className="flex flex-row items-center">
                            <img src={item.icon} alt=" " height="15px" width="15px" className="mr-4" />
                            <p className="text-sm">{item.name}</p>
                        </div>
                    </Link>
                )
            })}
        </>
    )
}

export default DashboardSidebar;
