import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  @media screen and (max-width: 425px) {
    height: auto;
  }
`;
export const ContentContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 5rem 0;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  & > div:first-child {
    margin: 0 auto 3rem;
    text-align: center;
  }
`;
