import React from "react";
// import styled from "styled-components";
import AppShowcase001 from "../../../../assets/AppShowcase001.png";
// import AppShowcase002 from "../../assets/AppShowcase002.png";
import AppShowcase004 from "../../../../assets/AppShowcase004.png";
import AppShowcase005 from "../../../../assets/AppShowcase005.png";
import AppShowcase006c from "../../../../assets/AppShowcaSse006c.png";
import AppShowcase007c from "../../../../assets/AppShowcase007c.png";
import AppShowcase005c from "../../../../assets/AppShowcase005c.png";
import {
  SectionSubTitle,
  SectionTitle,
} from "../commonElements/index.Elements";
import CustomCarousel from "./Carousel";
import { Container, ContentContainer } from "./section4.Elements";

// const Carousel = styled(CustomCarousel)`
//   padding-bottom: 1rem;
// `;

const Section4 = () => {
  const appShowcaseImage = [
    {
      comp: <img src={AppShowcase001} alt="AppShowcase001" />,
      id: 0,
    },
    {
      comp: <img src={AppShowcase005} alt="AppShowcase002" />,
      id: 1,
    },
    {
      comp: <img src={AppShowcase004} alt="AppShowcase004" />,
      id: 2,
    },
    {
      comp: <img src={AppShowcase005c} alt="AppShowcase005c" />,
      id: 3,
    }
  ];

  return (
    <Container>
      <ContentContainer>
        <div>
          <SectionTitle isBlack={true}>
            Checkout Our App Interface Look
          </SectionTitle>
          <SectionSubTitle isBlack={true}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
            nunc ante velit vitae. Est tellus vitae, nullam lobortis enim.
            Faucibus amet etiam tincidunt rhoncus, ullamcorper velit.
            Ullamcorper risus tempor, ac nunc libero urna, feugiat.
          </SectionSubTitle>
        </div>
        <div>
          <CustomCarousel compArr={appShowcaseImage} />
        </div>
      </ContentContainer>
    </Container>
  );
};

export default Section4;
