import React from "react";
import Slider from "react-slick";
import {ReactComponent as DownArrow} from "../../../assets/downArrow.svg";
import styled from "styled-components";
import {jumpAnimation} from "../../commons";
import {FaArrowRight} from 'react-icons/fa';


const StyledSvg = styled(DownArrow)`
  animation: ${jumpAnimation} 0.8s ease-in-out alternate-reverse infinite;
`;
const StyledBtn = styled('div')`
  button {
    display: none !important;
  }
`

const Section1 = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        vertical: true,
        verticalSwiping: true,
        autoplaySpeed: 1500,
        button: false
    };
    return (<section
            className="relative z-10 flex flex-col justify-center items-center gap-8 md:gap-14 w-11/12 py-8 m-auto text-white lg:w-4/5 h-vh-90">
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold w-full text-center">
                Grow your subscription business at 2X Speed
            </h1>
            <StyledBtn>
                <div style={{marginLeft: '-6%'}} className="w-full text-right flex text-xl font-medium lg:text-2xl ">
                    <h4 className="w-full">Subspace can help you &nbsp;</h4>
                    <div className="text-blue-600 w-full text-left	">
                        <div style={{height: '100px'}}>
                            <Slider {...settings}>
                                <div>
                                    <h3>increase discovery of your product</h3>
                                </div>
                                <div>
                                    <h3>streamline revenue operations</h3>
                                </div>
                                <div>
                                    <h3>identify the right customers</h3>
                                </div>
                                <div>
                                    <h3>lower the cost of acquiring new customers</h3>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </StyledBtn>
            <a href="https://cubetechnology.typeform.com/to/bApUpW8g" className="bg-darkBlue uppercase py-2 px-4 font-bold rounded-xl w-300 flex
        flex-row items-center justify-center gap-2">
                <FaArrowRight/>
                <span>Get Early Access</span>
            </a>
            <div className="absolute flex items-center justify-center w-full transform bottom-1/10">
                <StyledSvg/>
            </div>
        </section>);
};

export default Section1;
