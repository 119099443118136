import React from 'react';

const Settings = ()=> {
    return (
        <div className="text-blue-900">
            Settings
        </div>
    )
}

export default Settings;