import { useQuery } from "@apollo/client";
import React from "react";
import {} from "react-icons/bs";
// import { FaCalculator } from "react-icons/fa";
// import { RiScissorsFill } from "react-icons/ri";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
// import { ReactComponent as GroupOfPeople } from "../assets/groupOfPeople.svg";
import { FETCH_MEMBERS } from "../../graphql/query";
import { StyledSpinner } from "../commons";

const StyledDiv = styled.div`
  & li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const responsive = {
  superLargeDesktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 5,
    partialVisibilityGutter: 40,
  },
  desktop: {
    breakpoint: {
      max: 1024,
      min: 768,
    },
    items: 4,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: {
      max: 768,
      min: 425,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: {
      max: 425,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

interface dataType {
  designation: string;
  image: string;
  name: string;
}

const AboutUs = () => {
  window.scrollTo(0, 0);

  const { data } = useQuery<{ our_team: dataType[] }>(FETCH_MEMBERS);

  return (
    <section className="relative flex flex-col justify-center w-11/12 gap-16 py-16 m-auto overflow-hidden text-white md:py-32 md:gap-20 xl:w-4/5">
      <h1 className="text-3xl font-bold text-center">"Individuals can and do make a difference, but it takes a team to really mess things up. 🤗"</h1>

      {data ? (
        <StyledDiv>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            // className={styles.carouselContainer}
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {data.our_team.map((member, index) => (
              <div
                key={index}
                className="w-48 p-4 overflow-hidden text-black bg-white rounded-lg"
              >
                <img
                  src={member.image}
                  className="w-full rounded-full"
                  alt={member.name}
                  title={member.name}
                />
                <p className="text-lg font-bold text-center">{member.name}</p>
                <p className="text-sm text-center text-gray-500">
                  {member.designation}
                </p>
              </div>
            ))}
          </Carousel>
        </StyledDiv>
      ) : (
        <StyledSpinner />
      )}

      <div className="px-4 py-8 mx-auto sm:px-8 bg-lightBlue rounded-2xl">
        <h1 className="text-3xl font-bold text-center lg:text-4xl">
          Who are we?
        </h1>
        <p className="max-w-lg mx-auto mt-4 mb-8 text-sm text-center text-gray-200 lg:mb-12 lg:text-base">
          We're a diverse team of students, professionals, investors, and entrepreneurs passionate about improving the lives of everyday people. Every day we work collaboratively with Subspace members to create services that optimize their financial health.
        </p>
        {/*<div className="flex flex-col gap-8">*/}
        {/*  <div className="flex gap-4">*/}
        {/*    <div className="flex flex-col items-center justify-center w-1/5 gap-4">*/}
        {/*      <FaCalculator className="text-4xl" />*/}
        {/*      <p className="font-bold">Track</p>*/}
        {/*    </div>*/}
        {/*    <div className="flex-1">*/}
        {/*      <p className="text-base text-gray-100 lg:text-lg">*/}
        {/*        Its hard to keep track of subscriptions these days. We bring*/}
        {/*        them together in a single list so you never lose track of what*/}
        {/*        you're paying for.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="flex gap-4">*/}
        {/*    <div className="flex flex-col items-center justify-center w-1/5 gap-4">*/}
        {/*      <GroupOfPeople className="w-9" />*/}
        {/*      <p className="font-bold">Split</p>*/}
        {/*    </div>*/}
        {/*    <div className="flex-1">*/}
        {/*      <p className="text-base text-gray-100 lg:text-lg">*/}
        {/*        Split your subscriptions with Subspace.Select friends or*/}
        {/*        colleague you want to split your subscriptions with.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="flex gap-4">*/}
        {/*    <div className="flex flex-col items-center justify-center w-1/5 gap-4">*/}
        {/*      <RiScissorsFill className="text-4xl transform rotate-180" />*/}
        {/*      <p className="font-bold">Cancel</p>*/}
        {/*    </div>*/}
        {/*    <div className="flex-1">*/}
        {/*      <p className="text-base text-gray-100 lg:text-lg">*/}
        {/*        We cancel subscriptions on behalf of our members. Let us save*/}
        {/*        you time and hassle by canceling services so you don't have to.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </section>
  );
};

export default AboutUs;
