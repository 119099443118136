import React, {useState} from "react";
import BusinessDetailsForm from "./BusinessDetailsForm";
import BusinessOverviewForm from "./BusinessOverviewForm";
import ContactForm from "./ContactForm";

interface propsType {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Kyc = ({showModal, setShowModal}: propsType) => {
  const[active, setActive] = useState('contact');
  
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-9/10 lg:w-2/3 my-6 mx-auto">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col md:flex-row w-full outline-none focus:outline-none h-vh-90">
                
                    <div className="relative w-100 md:w-1/4 lg:w-1/3 bg-white h-auto rounded-t-lg md:rounded-l-lg md:rounded-t-none py-2 md:py-8">
                        <button className="text-black text-xl absolute top-4 right-4 md:hidden font-bold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}> x </button>
                        <div className="text-black px-8 w-100">
                            <p className="text-xl font-bold">KYC Form</p>
                            <p className="text-sm font-normal mt-3">Complete and submit the form to accept payments.</p>
                        </div>
                        <div className="my-3 md:my-8 w-100 flex flex-col">
                            <button className={`w-100 h-10 py-2 text-center ${active==='contact' ? 'bg-black text-white': 'text-black hover:bg-dashboardBg'}`}
                                onClick={() => setActive("contact")}>
                                Contact
                            </button>
                            <button className={`w-100 h-10 py-2 text-center ${active==='overview' ? 'bg-black text-white': 'text-black hover:bg-dashboardBg'}`}
                                onClick={() => setActive("overview")}>
                                Business Overview
                            </button>
                            <button className={`w-100 h-10 py-2 text-center ${active==='details' ? 'bg-black text-white': 'text-black hover:bg-dashboardBg'}`}
                                onClick={() => setActive("details")}>
                                Business Details
                            </button>
                        </div>
                    </div>
                    <div className="relative w-100 md:w-3/4 lg:w-2/3 h-auto rounded-b-lg md:rounded-r-lg md:rounded-l-none p-2 md:p-4 bg-dashboardBg">
                        {active==='contact'? <ContactForm setShowModal={setShowModal} />
                        : active==='overview' ? <BusinessOverviewForm setShowModal={setShowModal} />
                        : <BusinessDetailsForm setShowModal={setShowModal}/>}
                    </div>                    
                </div>
            </div>
          </div>
          <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Kyc;