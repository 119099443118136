import { useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FETCH_BLOGS } from "../../../graphql/query";
import { blogCard, titles } from "../../../Interface";
import { StyledSpinner } from "../../commons";
import BlogsCard from "./BlogsCard";

// const topics = [
//   "All",
//   "Movies",
//   "Food",
//   "Electronics",
//   "Liquor",
//   "Sports",
//   "Clothing",
//   "Water",
//   "EMI",
//   "Electricity",
// ];

const StyledDiv = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  /* @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  } */
`;

const Blogs = () => {
  const [currentTopic, setCurrentTopic] = useState("");
  const { loading, data, refetch } = useQuery<{ getSections: blogCard[]; getSectionClasses: titles[] }>(FETCH_BLOGS, {
    variables: {
      id: "42b0ee58-2303-4178-a1f7-f2786298f68d",
      title: ""
    },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  useEffect(() => {
    setCurrentTopic("");
  },[])

  const handleClick = (title: string) => {
    if(currentTopic !== title) {
      setCurrentTopic(title);
      refetch({
        title: title
      });
    }    
  }

  return (
    <section className="relative flex flex-col justify-center w-11/12 gap-16 py-16 m-auto text-white xl:w-4/5">
      <StyledDiv className="overflow-x-auto whitespace-nowrap text-center">
        {data?
          <p className={`inline-block font-medium p-4 text-xl cursor-pointer transform ease duration-300
            ${currentTopic==="" ? "text-white font-bold" : "text-gray-500 hover:text-white hover:-translate-y-1"}`}
            onClick={()=>handleClick("")}
          >
          All
        </p> : ''}

        {data?.getSectionClasses.map((item, index) => (
          <p
            key={index}
            className={`inline-block font-medium p-4 text-xl cursor-pointer transform ease duration-300
              ${currentTopic===item.title ? "text-white font-bold" : "text-gray-500 hover:text-white hover:-translate-y-1"}`}
            onClick={()=>handleClick(item.title)}
          >
            {item.title}
          </p>
        ))}
      </StyledDiv>
      {loading ? (
        <StyledSpinner></StyledSpinner>
      ) : (
        <StyledContainer className="gap-4">
          {data?.getSections.map((blog) => (
            <div key={blog.id}>
              <BlogsCard blog={blog} />
            </div>
          ))}
        </StyledContainer>
      )}
    </section>
  );
};

export default Blogs;
