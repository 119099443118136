import React, { useRef, useState } from 'react'
import { FaAngleDown } from 'react-icons/fa';

interface AccordionProps {
  title: React.ReactNode
  content: React.ReactNode
}

const Accordion: React.FC<AccordionProps> = ({ title, content }) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');
  const [rotate, setRotate] = useState('transform duration-700 ease');

  const contentSpace = useRef(null);

  function toggleAccordion() {
    setActive(!active)
    // @ts-ignore
    setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`)
    setRotate(active ? 'transform duration-300 ease' : 'transform duration-300 ease rotate-180')
  }

  return (
    <div className="w-100 flex flex-col border border-gray-400 rounded-lg">
      <button
        className="py-3 px-4 md:px-8 w-100 cursor-pointer focus:outline-none flex items-center justify-between"
        onClick={toggleAccordion}
      >
        <p className="inline-block text-left">{title}</p>
        <FaAngleDown className={`${rotate} inline-block`} />
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-700 ease-in-out px-4 md:px-8 w-100"
      >
        <div className="text-sm w-100 py-4">{content}</div>
      </div>
    </div>
  )
}

const Section5 = () => {
  return (
    <section className="p-2 flex flex-col items-center gap-8 md:gap-16 my-16 text-white w-11/12 lg:w-4/5 mx-auto">
      <h2 className="text-2xl md:text-4xl font-bold">Frequently Asked Questions</h2>
      <div className="w-100 flex flex-col gap-5">
        <Accordion 
          title="Q. How do I get started with subspace?" 
          content="Just Signup and enter your details. You can manually create your subscriptions, OR we will call you for the further process." />

        <Accordion 
          title="Q. How do I create subscriptions?" 
          content="To create a subscription, you need to fill out a simple form and enter necessary information like Plan Name, Plan details, Billing cycle, Plan Price, and Terms & Conditions." />

        <Accordion 
          title="Q. Is there a setup fee to use Subspace?"
          content="NO, You don’t need to pay any setup fee to use SubSpace." />
        <Accordion 
          title="Q. How do I get Paid?" 
          content="You need to link your bank account for all the transactions and click on the SETTLE NOW button. Money(GST pre deducted) will be credited to your bank account within two working days." />
      </div>
    </section>
  );
};

export default Section5;
