import React from 'react';

const Account = ()=> {
    return (
        <div className="bg-white w-9/10 mx-auto my-16 border border-gray-300 rounded-lg overflow-x-auto">
            <table className="rounded-lg w-full text-sm lg:text-md border-collapse">
                <tbody>
                    <tr className="border border-gray-300 bg-offWhite">
                        <th className="text-left px-8 py-3">Merchant Id : IPPJGIN1Nn6Lp2</th>
                        <th className="text-right px-8 py-3">
                            <button className="text-blue-500 text-md">Change Password</button>
                        </th>
                    </tr>

                    {/*Personal Detail */}
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2">Personal Details</th>
                    </tr>
                    <tr className="border border-gray-300 bg-offWhite">
                        <th className="text-left px-8 py-2">Display Name</th>
                        <th className="text-right px-8 py-2">Grow 90</th>
                    </tr>
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2">Contact Number</th>
                        <th className="text-right px-8 py-2">9876543210</th>
                    </tr>
                    <tr className="border border-gray-300 bg-offWhite">
                        <th className="text-left px-8 py-2">Contact Email</th>
                        <th className="text-right px-8 py-2">abc@gmail.com</th>
                    </tr>
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2">Business Name</th>
                        <th className="text-right px-8 py-2">Grow90</th>
                    </tr>
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2">Business Type</th>
                        <th className="text-right px-8 py-2">Unregistered</th>
                    </tr>
                    <tr className="h-10"></tr>

                    {/*Contact Detail */}
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2 font-bold">Contact Details</th>
                    </tr>
                    <tr className="border border-gray-300 bg-offWhite">
                        <th className="text-left px-8 py-2">Support Name</th>
                        <th className="text-right px-8 py-2">
                            <button className="text-blue-500 text-md">Edit Password</button>
                        </th>
                    </tr>
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2">Phone Number</th>
                        <th className="text-right px-8 py-2">9876543210</th>
                    </tr>
                    <tr className="border border-gray-300 bg-offWhite">
                        <th className="text-left px-8 py-2">Email Id</th>
                        <th className="text-right px-8 py-2">abc@gmail.com</th>
                    </tr>
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2">Website</th>
                        <th className="text-right px-8 py-2">grow90.org</th>
                    </tr>
                    <tr className="border border-gray-300 bg-offWhite">
                        <th className="text-left px-8 py-2">User Name</th>
                        <th className="text-right px-8 py-2">Grow90</th>
                    </tr>
                    <tr className="h-10"></tr>

                    {/*Bank Detail */}
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2 font-bold">Bank Details</th>
                    </tr>
                    <tr className="border border-gray-300 bg-offWhite">
                        <th className="text-left px-8 py-2">Bank Details</th>
                        <th className="text-right px-8 py-2">
                            <button className="text-blue-500 text-md">Request Change</button>
                        </th>
                    </tr>
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2">IFSC Code</th>
                        <th className="text-right px-8 py-2">9876543210</th>
                    </tr>
                    <tr className="border border-gray-300 bg-offWhite">
                        <th className="text-left px-8 py-2">Account Number</th>
                        <th className="text-right px-8 py-2">abc@gmail.com</th>
                    </tr>
                    <tr className="border border-gray-300">
                        <th className="text-left px-8 py-2">Account Holder</th>
                        <th className="text-right px-8 py-2">grow90.org</th>
                    </tr>
                    <tr className="h-10"></tr>
                </tbody>
            </table>
        </div>
    )
}

export default Account;