import React from 'react';

const Disputes = ()=> {
    return (
        <div className="text-blue-900">
            Disputes
        </div>
    )
}

export default Disputes;