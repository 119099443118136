import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import AboutUs from "../main/AboutUs";
import Blog from "../main/Blog";
import Blogs from "../main/blogs";
import Contact from "../main/Contact";
import HomePage from "../main/homePage";
import Store from "../main/store";
// import Navbar from "../navbars/Navbar";
// import Footer from "../footers/Footer";

interface propsType {
    isSignUpOpen: boolean;
    setIsSignUpOpen: React.Dispatch<React.SetStateAction<boolean>>;
    browserId: string;
}


function MainLayout({ browserId}: propsType) {
    return (
        <>
            
            <main>
                <Switch>
                    <Route exact path="/"><HomePage/></Route>
                    <Route path="/contact"><Contact/></Route>
                    <Route path="/about-us"><AboutUs/></Route>
                    {/*<Route path="/privacy-policy"><PrivacyPolicy /></Route>*/}
                    {/*<Route path="/terms-and-conditions"><TermsAndConditions /></Route>*/}
                    {/*<Route path="/returns"><ReturnAndRefunds /></Route>*/}
                    <Route path="/blogs"><Blogs/></Route>
                    <Route path="/blog/:url"><Blog browserId={browserId}/></Route>
                    <Route path="/business"><Store/></Route>
                    <Redirect from="/privacy-policy" to="/docs/2164c6c4-f004-43a1-89f0-ea8d56b2474e"/>
                    <Redirect from="/terms-and-conditions" to="/docs/a21395db-dd0e-45df-85c2-53488b290cce"/>
                    <Redirect from="/returns" to="/docs/59160b1a-d1e2-41ce-94d9-3f52134eb33f"/>
                    <Redirect from="*" to="/"/>
                </Switch>
            </main>
            <footer className="bg-footerBg">
                {/* <Footer/> */}
            </footer>
        </>
    )
}

export default MainLayout;
