import React, {useState} from 'react';
import Kyc from "./kyc/Kyc";

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div className="p-2 md:p-4">
            
            <Kyc showModal={showModal} setShowModal={setShowModal} />
            <p>Welcome to your Dashbaoard, Grow90</p>

            <div className="bg-white w-full my-8 p-2 md:py-8 md:px-4 rounded-lg overflow-x-auto flex flex-col gap-2 md:gap-4">
                <p className="w-full font-semibold">
                    Your are just steps away from enabling live payments
                </p>
                <p className="w-full">
                    Submit a few KYC details and start accepting payments from your customers
                </p>
                <div className="w-9/10 mx-auto flex flex-col md:flex-row">
                    <div className="w-full md:w-1/3 text-center flex flex-col gap-2 p-2 lg:px-4">
                        <div className="font-semibold w-8 h-8 mx-auto rounded-full bg-green text-white flex justify-center items-center">1</div>
                        <div className="font-semibold">Contact Details</div>
                    </div>
                    <div className="w-full md:w-1/3 text-center flex flex-col gap-2 p-2 lg:px-4">
                        <div className="font-semibold w-8 h-8 mx-auto rounded-full bg-gray-400 text-white flex justify-center items-center">2</div>
                        <div className="font-semibold">Live payments and Settlements</div>
                        <p className="text-sm">
                            Submit a few KYC details to start accepting payments and receive settlement in your account
                        </p>
                        <button
                            className="bg-blue-600 hover:bg-blue-800 text-white text-sm p-2 rounded w-1/2 mx-auto"
                            type="button"
                            onClick={() => setShowModal(true)}>
                            Submit KYC
                        </button>
                    </div>
                    <div className="w-full md:w-1/3 text-center flex flex-col gap-2 p-2 lg:px-4">
                        <div className="font-semibold w-8 h-8 mx-auto rounded-full bg-gray-400 text-white flex justify-center items-center">3</div>
                        <div className="font-semibold">Account Activation</div>
                        <p className="text-sm">
                            Get all the KYC details approved to complete the account activation
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
