import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  & > img:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
  }
  & > div:last-child {
    width: 100%;
    height: 40%;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -9;
    @media screen and (max-width: 1024px) {
      height: 30%;
    }
    @media screen and (max-width: 425px) {
      height: 20%;
    }
  }
`;
export const ContentContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 5rem 0 0;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  & > div:first-child {
    text-align: center;
    color: white;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > img {
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  & > :nth-child(2) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
`;
