import React from "react";
import {ReactComponent as Features1} from "../../../assets/store/features1.svg";
import {ReactComponent as Features2} from "../../../assets/store/features2.svg";
import {ReactComponent as Features3} from "../../../assets/store/features3.svg";
import {ReactComponent as Features4} from "../../../assets/store/features4.svg";
import {ReactComponent as Features5} from "../../../assets/store/features5.svg";
import {ReactComponent as Features6} from "../../../assets/store/features6.svg";
import {ReactComponent as Features7} from "../../../assets/store/features7.svg";
import store5 from "../../../assets/store/store5.png";
import store6 from "../../../assets/store/store6.png";
import store7 from "../../../assets/store/store7.png";
import store8 from "../../../assets/store/store8.png";
import store9 from "../../../assets/store/store9.png";
import store10 from "../../../assets/store/store10.png";
import store11 from "../../../assets/store/store11.png";
import { StyledImage } from "../../commons";

const Section4 = () => {
  const classes = {
    outerDiv : "w-full flex flex-col items-center justify-between gap-16 md:gap-0 md:flex-row md:mt-10",
    innerDiv : "flex flex-col gap-4 items-center justify-center w-11/12 md:w-2/5",
    titleDiv : "flex flex-row gap-4 items-center",
    titleP : "text-lg md:text-xl font-bold",
    detailP : "text-sm md:text-base font-normal px-2 lg:px-8"
  }

  return (
    <section className="text-center flex flex-col items-center gap-16 my-16 text-white w-11/12 lg:w-4/5 mx-auto">
      <h2 className="text-2xl md:text-4xl font-bold">
        Smart features. Incredible growth.
      </h2>

      <div className={classes.outerDiv}>
        <div className={classes.innerDiv}>
          <div className={classes.titleDiv}>
            <Features1 className="h-50 w-50"/>
            <p className={classes.titleP}>Distributed Economy</p>
          </div>
          <div className="w-300 lg:w-400 h-200 lg:h-300">
            <StyledImage src={store5} alt="store 5" className="w-full h-full"/>
          </div>
          <p className={classes.detailP}>
            Get access to the Diverse audience of your preference.
          </p>
        </div>

        <div className={classes.innerDiv}>
          <div className={classes.titleDiv}>
            <Features2 className="h-50 w-50"/>
            <p className={classes.titleP}>Curated Marketplace</p>
          </div>
          <div className="w-300 lg:w-400 h-200 lg:h-300">
            <StyledImage src={store6} alt="store 6" className="w-full h-full"/>
          </div>
          <p className={classes.detailP}>
            Access every type of user specially segregated and curated by our team.
          </p>
        </div>
      </div>

      <div className={classes.outerDiv}>
        <div className={classes.innerDiv}>
          <div className={classes.titleDiv}>
            <Features3 className="h-50 w-50"/>
            <p className={classes.titleP}>Capitalization of untapped potential</p>
          </div>
          <div className="w-300 lg:w-400 h-200 lg:h-300">
            <StyledImage src={store7} alt="store 7" className="w-full h-full"/>
          </div>
          <p className={classes.detailP}>
            Subscription merchants get the opportunity to capture unexplored but highly potential Users.
          </p>
        </div>

        <div className={classes.innerDiv}>
          <div className={classes.titleDiv}>
            <Features4 className="h-50 w-50"/>
            <p className={classes.titleP}>Community like experience</p>
          </div>
          <div className="w-300 lg:w-400 h-200 lg:h-300">
            <StyledImage src={store8} alt="store 8" className="w-full h-full"/>
          </div>
          <p className={classes.detailP}>
            Subspace is home to all the subscription merchants to communicate, discuss, and feel welcomed.
          </p>
        </div>
      </div>

      <div className={classes.outerDiv}>
        <div className={classes.innerDiv}>
          <div className={classes.titleDiv}>
            <Features5 className="h-50 w-50"/>
            <p className={classes.titleP}>Simple workflow for the seller</p>
          </div>
          <div className="w-300 lg:w-400 h-200 lg:h-300">
            <StyledImage src={store9} alt="store 9" className="w-full h-full"/>
          </div>
          <p className={classes.detailP}>
            Get access to the best developer tools, data, and information along with our helping team to make your work here as minimal and straightforward as a piece of cake.
          </p>
        </div>

        <div className={classes.innerDiv}>
          <div className={classes.titleDiv}>
            <Features6 className="h-50 w-50"/>
            <p className={classes.titleP}>An exceptional customer experience</p>
          </div>
          <div className="w-300 lg:w-400 h-200 lg:h-300">
            <StyledImage src={store10} alt="store 10" className="w-full h-full"/>
          </div>
          <p className={classes.detailP}>
            We offer help any time, any day, 24*7 365 days.
          </p>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center justify-between md:justify-start md:mt-10">
        <div className={classes.innerDiv}>
          <div className={classes.titleDiv}>
            <Features7 className="h-50 w-50"/>
            <p className={classes.titleP}>Aid to local subscription merchant</p>
          </div>
          <div className="w-300 lg:w-400 h-200 lg:h-300">
            <StyledImage src={store11} alt="store 11" className="w-full h-full"/>
          </div>
          <p className={classes.detailP}>
            We are the working arm of local merchants. From information collection to interpretation, we all do it here.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Section4;
