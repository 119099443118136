import React from "react";
// import AppleStoreImage from "../../assets/AppleStoreImage.png";
import AppShowcase003 from "../../../../assets/AppShowcase003.png";
import BackgroundImage from "../../../../assets/Background Image.png";
import GooglePlayStoreImage from "../../../../assets/GooglePlayStoreImage.png";
import Navbar from "../../../navbars/Navbar";
// import Header from "";
import {
  Container,
  ContentContainer,
  ContentDiv,
  ImgContainer,
} from "./section1.Elements";

// interface propsType {
//   setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
// }


const Section1 = () => {
  return (
    <>
    
      <Container>
          <nav  className="w-11/12 m-auto xl:w-4/5 ">
            <Navbar  />
          </nav>
      {/* <Header setIsDrawerOpen={setIsDrawerOpen} /> */}
      <img src={BackgroundImage} alt="BackgoundImage" />
      <ContentContainer>
        <ContentDiv>
          <h2 style={{marginLeft:'-14px'}}>An app that makes learning easier</h2>
          <p>
          Feeling Left Behind in Preparation? Learn From An App That Focus On Your Learning Gaps Precisely & Saves Your Precious Time.


          </p>
          <p>Download App Now</p>
          <ImgContainer>
            <a href="https://play.google.com/store/apps/details?id=org.grow90.whatsub">
              <img src={GooglePlayStoreImage} alt="Google Play Store" />
            </a>
            {/*<a href="#">*/}
            {/*  <img src={AppleStoreImage} alt="Apple Store" />*/}
            {/*</a>*/}
          </ImgContainer>
        </ContentDiv>
        <div>
          <img src={AppShowcase003} alt="AppShowcase003" />
        </div>
      </ContentContainer>
    </Container>
    </>
    
  );
};

export default Section1;
