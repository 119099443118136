import React, { ReactNode } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import styles from "./style.module.css";
// import { ReactComponent as LeftArrowIcon } from "../assets/LeftArrowIcon.svg";
// import { ReactComponent as RightArrowIcon } from "../assets/RightArrowIcon.svg";

interface propsType {
  compArr: {
    comp: ReactNode;
    id: number;
  }[];
}

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    max-width: 100%;
  }
`;
const responsive = {
  superLargeDesktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 4,
    partialVisibilityGutter: 40,
  },
  desktop: {
    breakpoint: {
      max: 1024,
      min: 768,
    },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: {
      max: 768,
      min: 425,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: {
      max: 425,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

const CustomCarousel = ({ compArr }: propsType) => {
  return (
    <>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className={styles.carouselContainer}
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {compArr.map((comp) => (
          <ImageContainer key={comp.id}>{comp.comp}</ImageContainer>
        ))}
      </Carousel>
    </>
  );
};

export default CustomCarousel;
