import React, {useState} from 'react';

const Tabs = [
    {
        id: 1,
        name: 'Payments'
    },
    {
        id: 2,
        name: 'Refunds'
    },
    {
        id: 3,
        name: 'Orders'
    }
]

const Transactions = ()=> {
    const[activeTab, setActiveTab] = useState(1);
    return (
        <div className="p-2 md:p-4 lg:p-8">
            <div className="bg-white w-full my-8 rounded-lg overflow-x-auto flex flex-col">
                <div className="border-b-2 border-gray-300 w-full h-16 p-0">
                    <div className="w-full h-full md:w-1/2 lg:w-2/5 flex flex-row">
                        {Tabs.map(tab =>{
                            return(
                                <button key={tab.id} onClick={() => setActiveTab(tab.id)}
                                    className={`flex items-center justify-center w-1/3 h-full font-semibold outline-none focus:outline-none
                                        ${activeTab===tab.id ? "border-b-4 text-blue-600 border-blue-600 transition ease-in duration-200" : ""} hover:bg-gray-100`}>
                                    {tab.name}
                                </button>
                            )
                        })}                    
                    </div>
                </div>
                <div>
                    {activeTab===1 ? "Payments" : activeTab ===2 ? "Refunds" : "Orders"}
                </div>
            </div>            
        </div>
    )
}

export default Transactions;
