import React, {useState} from 'react';

interface propsType {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactForm = ({setShowModal}: propsType) => {
    const[cName, setCName] = useState('');
    const[cNum, setCNum] = useState('');
    const[cEmail, setCEmail] = useState('');

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(cName,cNum,cEmail);
    }

    return (
        <>
            <div className="uppercase flex flex-row justify-between items-center ">
                <p className="text-xl font-medium uppercase">Contact Info</p>
                <button className="text-black text-xl font-bold outline-none focus:outline-none hidden md:block"
                    onClick={() => setShowModal(false)}> x </button>
            </div>
            <div className="my-8 md:my-16 text-sm md:px-8">
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center">
                        <label htmlFor="cName" className="w-full md:w-1/3">Contact Name<span className="text-red-500">*</span></label>
                        <input required
                            className="outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            type="text" 
                            id="cName" 
                            name="cName"
                            value={cName}
                            onChange={(e)=> setCName(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center">
                        <label htmlFor="cNum" className="w-full md:w-1/3">Contact Number<span className="text-red-500">*</span></label>
                        <input required
                            className="outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            type="number" 
                            id="cNum" 
                            name="cNum"
                            value={cNum}
                            onChange={(e)=> setCNum(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center">
                        <label htmlFor="cEmail" className="w-full md:w-1/3">Contact Email<span className="text-red-500">*</span></label>
                        <input required
                            className="outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            type="email"
                            id="cEmail" 
                            name="cEmail"
                            value={cEmail}
                            onChange={(e)=> setCEmail(e.target.value)} />
                    </div>
                    <div className="absolute bottom-0 left-0 w-full border-t border-gray-400 p-3 min-h-50 flex flex-row justify-end items-center gap-4">
                        <button type="submit" className="border border-black text-black text-sm py-2 px-3 rounded-lg">
                            Save
                        </button>
                        <button type="submit" className="bg-black text-white text-sm py-2 px-3 rounded-lg">
                            Save & Next
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ContactForm;
