import React from 'react';

const CustomerList = [
    {
        id: 'pay_G11ZY646x6Afk3',
        name: 'Anonymous 1',
        email: 'abc@gmail.com',
        contact: '+919876543210'
    },
    {
        id: 'pay_G11ZY646x6Afk3',
        name: 'Anonymous 2',
        email: 'abc@gmail.com',
        contact: '+919876543210'
    },
    {
        id: 'pay_G11ZY646x6Afk3',
        name: 'Anonymous 3',
        email: 'abc@gmail.com',
        contact: '+919876543210'
    },
    {
        id: 'pay_G11ZY646x6Afk3',
        name: 'Anonymous 4',
        email: 'abc@gmail.com',
        contact: '+919876543210'
    },
    {
        id: 'pay_G11ZY646x6Afk3',
        name: 'Anonymous 5',
        email: 'abc@gmail.com',
        contact: '+919876543210'
    }
]

const Customers = ()=> {
    return (
        <div className="bg-white w-9/10 mx-auto my-16 rounded-lg overflow-x-auto border border-gray-300">
            <div className="w-full h-16 flex flex-row justify-between items-center px-4">
                <div className="h-full flex items-center justify-center px-4 border-b-4 border-blue-600 text-blue-600 font-semibold">
                    Customers
                </div>
                
                <button className="bg-black shadow hover:shadow-lg py-1 px-2 md:py-2 md:px-3 text-white rounded-md text-sm lg:text-md">
                    + New Customer
                </button>               
            </div>
            
            <table className="w-full border-collapse text-sm lg:text-md">
                <thead className="bg-offWhite">
                    <tr className="w-full h-10 bg-white border-t-2 border-gray-300"></tr>
                    <tr>
                    <th className="border border-l-0 border-gray-300 py-2">Customer Id</th>
                    <th className="border border-gray-300 py-2">Customer Name</th>
                    <th className="border border-gray-300 py-2">Email</th>
                    <th className="border border-r-0 border-gray-300 py-2">Contact</th>
                    </tr>
                </thead>
                <tbody>
                    {CustomerList.map((customer, index) => {
                        return(
                            <tr key={index}>
                                <td className="border border-l-0 border-gray-300 px-4 md:px-8 py-2 text-blue-600">{customer.id}</td>
                                <td className="border border-gray-300 px-4 md:px-8 py-2">{customer.name}</td>
                                <td className="border border-gray-300 px-4 md:px-8 py-2">{customer.email}</td>
                                <td className="border border-r-0 border-gray-300 px-4 md:px-8 py-2">{customer.contact}</td>
                            </tr>
                        )
                    })}                    
                </tbody>
            </table> 
        </div>
    )
}

export default Customers;