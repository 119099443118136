import React from "react";
import { IoMdMail } from "react-icons/io";
import { MdPhoneInTalk } from "react-icons/md";

const Contact = () => {
  window.scrollTo(0, 0);

  return (
    <section className="relative flex flex-col justify-center w-11/12 gap-16 py-16 m-auto overflow-hidden text-white md:py-32 md:gap-20 xl:w-4/5">
      <h1 className="text-6xl font-bold text-center">Contact us</h1>
      <div className="flex flex-col items-center justify-center gap-8 sm:flex-row">
        <div className="flex flex-col items-center justify-center w-56 gap-4 px-10 py-8 text-black bg-white rounded-lg">
          <div className="p-6 bg-gray-100 rounded-full">
            <IoMdMail className="text-3xl" />
          </div>
          <p className="text-2xl font-bold">Email</p>
          <p className="text-xs text-gray-500">mailus@subspace.money</p>
        </div>
        <div className="flex flex-col items-center justify-center w-56 gap-4 px-10 py-8 text-black bg-white rounded-lg">
          <div className="p-6 bg-gray-100 rounded-full">
            <MdPhoneInTalk className="text-3xl" />
          </div>
          <p className="text-2xl font-bold">Phone</p>
          <p className="text-xs text-gray-500">+91 9148816419</p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
