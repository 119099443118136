import React from "react";
import { Link } from "react-router-dom";
import { blogCard } from "../../../Interface";

interface propsType {
  blog: blogCard;
}


const BlogsCard = ({ blog }: propsType) => {
  return (
    <div className="flex items-center justify-center overflow-y-hidden">
      
      <Link to={`/blog/${blog.url}`}>
        <div className="w-64 max-w-full pb-4">
          <div className="flex items-center justify-center w-full overflow-hidden rounded-md h-44">
            {blog.image_url.length > 0 ? (
              <img className="rounded-md" src={blog.image_url} alt="blog" />
            ) : (
              "img"
            )}
          </div>
          
          <div className="flex flex-col gap-2 py-2">
            <p>{blog.section_title}</p>
            <h2 className="text-xl font-bold">{blog.title}</h2>
            {/* <p className="text-xs">{blog.subtitle}</p> */}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogsCard;
