import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import React, {useState} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {v4 as uuid} from "uuid";
import AuthLayout from "./components/layout/AuthLayout";
import MainLayout from "./components/layout/MainLayout";
import DashboardLayout from "./components/layout/DashboardLayout";
import Blog from "./components/main/Blog";

const client = new ApolloClient({
    uri: "https://db.grow90.org/v1/graphql", cache: new InMemoryCache(),
});

function App() {
    let browserId = window.localStorage.getItem("browserUniqueId");
    if (!browserId) {
        browserId = uuid();
        window.localStorage.setItem("browserUniqueId", browserId);
    }

    const [isSignUpOpen, setIsSignUpOpen] = useState(false);

    return (<ApolloProvider client={client}>
        <BrowserRouter>
            <div className="relative w-full min-h-screen overflow-x-hidden">
                <Switch>
                    <Route path="/auth"><AuthLayout/></Route>
                    <Route path="/dashboard"><DashboardLayout/></Route>
                    <Route path="/docs/:url"><Blog browserId={browserId}/></Route>
                    <Route path="/">
                        <MainLayout isSignUpOpen={isSignUpOpen} setIsSignUpOpen={setIsSignUpOpen}
                                    browserId={browserId}/>
                    </Route>
                    <Redirect from="*" to="/"/>
                </Switch>
            </div>
        </BrowserRouter>
    </ApolloProvider>);
}

export default App;
