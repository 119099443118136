import styled from "styled-components";

export const Container = styled.div`
  padding: 30px 15px 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  display: flex;
  gap: 10px;
  @media screen and (max-width: 425px) {
    width: 100%;
  }
  & svg {
    width: 5rem;
  }
  & div h3 {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: normal;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }
  & div p {
    color: #6c6c72;
    line-height: 24px;
    @media screen and (max-width: 1024px) {
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
