import gql from "graphql-tag";

export const LIKE_BLOG = gql`
  mutation likeBog($blog_id: uuid!, $browser_id: String!) {
    insert_coursex_blog_likes_one(
      object: { blog_id: $blog_id, browser_id: $browser_id }
    ) {
      id
    }
  }
`;

export const UNLIKE_BLOG = gql`
  mutation unlikeBlog($blog_id: uuid!, $browser_id: String!) {
    delete_coursex_blog_likes(
      where: {
        _and: { blog_id: { _eq: $blog_id }, browser_id: { _eq: $browser_id } }
      }
    ) {
      affected_rows
    }
  }
`;
