import React from "react";
import { ReactComponent as FacebookIcon } from "../../../../assets/FacebookIcon.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/InstagramIcon.svg";
import Location from "../../../../assets/location.png";
import Phone from "../../../../assets/phone.png";
import Envelop from "../../../../assets/envelop.png";
import { ReactComponent as TwitterIcon } from "../../../../assets/TwitterIcon.svg";
import { ReactComponent as YoutubeIcon } from "../../../../assets/YoutubeIcon.svg";
import { Container, ContentContainer,ReachUs } from "./footer.Elements";

const Footer = () => {
  return (
    <>
      <Container >
      <ContentContainer>
        <div>
          <div>
            <h3>IMPULSE</h3>
            <p>
            Impulse Education Pvt Ltd.
            </p>
            <div>
              <div>
                <FacebookIcon />
              </div>
              <div>
                <InstagramIcon />
              </div>
              <div>
                <TwitterIcon />
              </div>
              <div>
                <YoutubeIcon />
              </div>
            </div>
          </div>
          <div>
            <h3>quick link</h3>
            <a href="https://subspace.money/about-us">About</a>
            <a href="/docs/whatsub-single-docs-subspace-policies-terms-and-conditions">Privacy Policy</a>
            <a href="/docs/whatsub-single-docs-subspace-policies-terms-and-conditions">Terms & Conditions</a>
            <a href="https://subspace.money/contact">Contact</a>
          </div>
          <div>
            <h3>Reach us</h3>
            <p>Subscribe our newsletter to get our latest update & news</p>
            <ReachUs>
              {/* <input type="text" placeholder="Your email address" />
              <div>
                <img src={SendIcon} alt="Send Icon" />
              </div> */}
              <span className="flex">
                  <img src={Location} alt="" />
                  <p style={{marginLeft:'10px',marginBottom:'0'}}>Mylearning Plus Pvt. Ltd.
                  C Cube Building, Plot No. 67, 2nd Floor,
                  Road No 9A, Jubilee Hills, Hyderabad</p>
               </span>
               <span className="flex">
                  <img src={Envelop} alt="" />
                  <p style={{marginLeft:'10px',marginBottom:'0'}}>abc@gmail.com</p>
                </span >
                <span className="flex">
                  <img src={Phone} alt="" />
                  <p style={{marginLeft:'10px',marginBottom:'0'}}>+91 1234567890</p>
                </span>
            </ReachUs>
          </div>
        </div>
        <hr />
        <p>&copy; Copyright 2021 .IMPULSE. All Right Reserved.</p>
      </ContentContainer>
    </Container>
    </>
    
  );
};

export default Footer;

