import React from "react";
// import AppleStoreImage from "../../assets/AppleStoreImage.png";
import { ReactComponent as DownloadIcon } from "../../../../assets/DownloadIcon.svg";
import GooglePlayStoreImage from "../../../../assets/GooglePlayStoreImage.png";
import GravitySceneiPhone12Mockup from "../../../../assets/GravitySceneiPhone12Mockup.png";
import { ReactComponent as LikeIcon } from "../../../../assets/LikeIcon.svg";
import { ReactComponent as StarIcon } from "../../../../assets/StarIcon.svg";
import {
  Container,
  ContentContainer,
  ImgContainer,
  Stats,
  StatsContainer,
} from "./section5.Elements";

const Section5 = () => {
  return (
    <Container>
      <ContentContainer>
        <div>
          <h2 style={{display:'block'}}>Download App Now</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
            nunc ante velit vitae. Est tellus vitae, nullam lobortis enim.
            Faucibus amet etiam tincidunt rhoncus, ullamcorper velit.
            Ullamcorper risus tempor, ac nunc libero urna, feugiat.
          </p>
          <ImgContainer>
            <a href="https://play.google.com/store/apps/details?id=org.grow90.whatsub">
              <img src={GooglePlayStoreImage} alt="Google Play Store" />
            </a>
            {/*<a href="#">*/}
            {/*  <img src={AppleStoreImage} alt="Apple Store" />*/}
            {/*</a>*/}
          </ImgContainer>
          <StatsContainer>
            <Stats style={{backgroundColor:'#514fb2'}}>
              <DownloadIcon />
              <p>59865</p>
              <p>Download</p>
            </Stats>
            <Stats style={{backgroundColor:'#514fb2'}}>
              <LikeIcon />
              <p>29852</p>
              <p>like</p>
            </Stats>
            <Stats style={{backgroundColor:'#514fb2'}}>
              <StarIcon />
              <p>1500</p>
              <p>5 star rating</p>
            </Stats>
          </StatsContainer>
        </div>
        <div>
          <img src={GravitySceneiPhone12Mockup} alt="App on Iphone" />
        </div>
      </ContentContainer>
    </Container>
  );
};

export default Section5;
