import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  @media screen and (max-width: 1024px) {
    padding: 3rem 0;
  }
  @media screen and (max-width: 425px) {
    height: auto;
  }
`;
export const UpperContainer = styled.div`
  margin-bottom: 5rem;
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;
export const LowerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 90%;
    & > div:first-child {
      max-width: 40%;
      & img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 425px) {
    flex-direction: column;
    gap: 3rem;
  }
  & > div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
  & > div:first-child {
    @media screen and (max-width: 425px) {
      max-width: 100%;
      & img {
        width: 80%;
      }
    }
  }
  & > div:last-child {
    flex-direction: column;
    gap: 40px;
  }
`;
