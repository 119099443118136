import { Link } from "react-router-dom";
import sony_img from "../../assets/sony__.png";
import toi_img from "../../assets/toi__.png";
import mb_img from "../../assets/mb__.png";
import zee_img from "../../assets/zee__.png";
import netflix_img from "../../assets/netflix__.png";
import curefit_img from "../../assets/curefit__.png";
import aha_img from "../../assets/aha__.png";
import nxt_img from "../../assets/nxt__.png";
import disney_img from "../../assets/disney__.png";

const Advertisement = () => {
    return(
        <>
            <p className="font-bold text-center text-black">
                Why Choose Subspace
            </p>
            <p className="p-2 text-center">
                2000+ businesses trust their payments with subspace
            </p>
            <div className="my-4">
                <div className="flex items-center justify-evenly">
                    <div className="w-16 lg:w-20 h-16 lg:h-20">
                        <img
                        className="object-cover w-full h-full"
                        alt=""
                        src={toi_img}
                        />
                    </div>
                    <div className="w-16 lg:w-20 h-16 lg:h-20">
                        <img
                        className="object-cover w-full h-full"
                        alt=""
                        src={mb_img}
                        />
                    </div>
                    <div className="w-16 lg:w-20 h-16 lg:h-20">
                        <img
                        className="object-cover w-full h-full"
                        alt=""
                        src={nxt_img}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-evenly my-8">
                    <div className="w-16 lg:w-20 h-16 lg:h-20">
                        <img
                        className="object-cover w-full h-full"
                        alt=""
                        src={sony_img}
                        />
                    </div>
                    <div className="w-16 lg:w-20 h-16 lg:h-20">
                        <img
                        className="object-cover w-full h-full"
                        alt=""
                        src={netflix_img}
                        />
                    </div>
                    <div className="w-16 lg:w-20 h-16 lg:h-20">
                        <img
                        className="object-cover w-full h-full"
                        alt=""
                        src={zee_img}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-evenly">
                    <div className="w-16 lg:w-20 h-16 lg:h-20">
                        <img
                        className="object-cover w-full h-full"
                        alt=""
                        src={disney_img}
                        />
                    </div>
                    <div className="w-16 lg:w-20 h-16 lg:h-20">
                        <img
                        className="object-cover w-full h-full"
                        alt=""
                        src={aha_img}
                        />
                    </div>
                    <div className="w-16 lg:w-20 h-16 lg:h-20">
                        <img
                        className="object-cover w-full h-full"
                        alt=""
                        src={curefit_img}
                        />
                    </div>
                </div>
            </div>
            <p className="text-xs text-center mt-8">
                Need help ? We are just a click away{" "}
                <Link to="/contact" className="font-bold underline">Contact Us</Link>
            </p>
        </>
    )
}

export default Advertisement;