import React from "react";

const Section2 = () => {
  return (
    <section className="relative text-center z-10 p-2 flex flex-col items-center gap-11 my-8 text-white w-11/12 lg:w-4/5 mx-auto">
      <h2 className="text-2xl md:text-4xl font-bold mt-16">
        Whichever type of subscription you own, we help you to grow
      </h2>
      <div className="w-full flex flex-wrap items-center justify-center gap-2 lg:gap-4 font-bold">
        <p
          className="p-2 flex items-center justify-center rounded-3xl w-2/5 lg:w-1/4 h-14"
          style={{ backgroundColor: "#69DADB" }}
        >
          Education
        </p>
        <p
          className="p-2 flex items-center justify-center rounded-3xl w-2/5 lg:w-1/4 h-14"
          style={{ backgroundColor: "#C32BAD" }}
        >
          Music
        </p>
        <p
          className="p-2 flex items-center justify-center rounded-3xl w-2/5 lg:w-1/4 h-14"
          style={{ backgroundColor: "#F56FAD" }}
        >
          e-Commerce
        </p>
        <p
          className="p-2 flex items-center justify-center rounded-3xl w-2/5 lg:w-1/4 h-14"
          style={{ backgroundColor: "#193498" }}
        >
          News
        </p>
        <p
          className="p-2 flex items-center justify-center rounded-3xl w-2/5 lg:w-1/4 h-14"
          style={{ backgroundColor: "#316B83" }}
        >
          Video
        </p>
        <p
          className="p-2 flex items-center justify-center rounded-3xl w-2/5 lg:w-1/4 h-14"
          style={{ backgroundColor: "#B1E693" }}
        >
          Grocery
        </p>
        <p
          className="p-2 flex items-center justify-center rounded-3xl w-2/5 lg:w-1/4 h-14"
          style={{ backgroundColor: "#63B4B8" }}
        >
          Fitness
        </p>
        <p
          className="p-2 flex items-center justify-center rounded-3xl w-2/5 lg:w-1/4 h-14"
          style={{ backgroundColor: "#F6841D55" }}
        >
          & Many more
        </p>
      </div>
    </section>
  );
};

export default Section2;
