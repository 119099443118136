import React from 'react';

const Rewards = ()=> {
    return (
        <div className="text-blue-900">
            Rewards
        </div>
    )
}

export default Rewards;