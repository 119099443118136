import styled from "styled-components";

export const Container = styled.div`
  padding-top: 30px;
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;
export const Contacts = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media screen and (max-width: 1024px) {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 425px) {
    display: none;
  }
`;
export const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  color: white;
  & p {
    margin-left: 20px;
  }
  & p:nth-child(2) {
    margin-right: 40px;
  }
`;
export const Socials = styled.div`
  display: flex;
  align-items: center;
`;
export const SocialIcon = styled.a`
  margin: 0 15px;
  &:last-child {
    margin: 0 0 0 15px;
  }
`;
export const MainHeaderContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;
export const MenuIconContainer = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  display: none;
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const HeaderLinks = styled.div`
  display: flex;
  align-items: center;
  margin-left: 50px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
  & a {
    text-decoration: none;
    color: black;
    font-size: 20px;
    text-transform: uppercase;
    margin-right: 20px;
  }
  & a:last-child {
    margin-right: 0;
  }
`;
export const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 25px 15px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 425px) {
    padding: 5px 5px;
    width: 40%;
    height: 70px;
    & img {
      width: 100%;
    }
  }
`;
export const Button = styled.button`
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  @media screen and (max-width: 425px) {
    padding: 10px 5px;
    font-size: 1rem;
  }
`;
