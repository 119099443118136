import React from "react";
import androidSmartphoneFreeMockup from "../../../../assets/android-smartphone-free-mockup.png";
import {
  SectionSubTitle,
  SectionTitle,
} from "../commonElements/index.Elements";
import AboutCard from "./aboutCard";
import { Container, LowerContainer, UpperContainer } from "./section2.Elements";

const Section2 = () => {
  return (
    <Container>
      <UpperContainer>
        <SectionTitle isBlack={true}></SectionTitle>
        <SectionSubTitle isBlack={true}>
        
        </SectionSubTitle>
      </UpperContainer>
      <LowerContainer>
        <div>
          <img
          style={{width:'89%',marginRight:'30%'}}
            src={androidSmartphoneFreeMockup}
            alt="android smartphone free mockup"
          />
        </div>
        
        <div style={{textAlign:'start'}}>
        <h2 >How <span style={{color:'blue'}}>IMpulse</span>  works</h2>
          <AboutCard
            title="Daily Live classes"
            description="Daily live classes
            Chat with educators, ask questions, answer live polls, and get your doubts cleared - all while the class is going on"
          />
          <AboutCard
            title="Practice and revise"
            description="Learning isn't just limited to classes with our practice section, mock tests and lecture notes shared as PDFs for your revision"
          />
          <AboutCard
            title="Learn anywhere, anytime"
            description="One subscription gets you access to all our live and recorded classes to watch from the comfort of any of your devices"
          />
        </div>
      </LowerContainer>
    </Container>
  );
};

export default Section2;
