import styled, { keyframes } from "styled-components";

export const jumpAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-8px);
  }
`;

export const StyledImage = styled.img`
  animation: ${jumpAnimation} 0.8s ease-in-out alternate-reverse infinite;
`;

export const StyledSpin = keyframes`
0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const StyledSpinner = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 5px solid transparent;
  border-left: 5px solid white;
  animation: ${StyledSpin} 1s linear infinite;
  margin: auto;
`;
