import styled from "styled-components";

export const Container = styled.div`
  background-color: black;
`;
export const ContentContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  color: white;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  & > div {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: space-between;
    /* gap: 3rem; */
    padding: 4rem 0;
    @media screen and (max-width: 1024px) {
      gap: 1rem;
    }
    @media screen and (max-width: 425px) {
      grid-template-columns: 100%;
    }
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & h3 {
        font-size: 1.5rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
        @media screen and (max-width: 425px) {
          font-size: 2rem;
        }
      }
      & p {
        margin-bottom: 1rem;
        @media screen and (max-width: 425px) {
          font-size: 1.2rem;
          line-height: 24px;
        }
      }
      &:nth-child(2) a {
        text-decoration: none;
        color: white;
        margin-bottom: 0.5rem;
        width: fit-content;
        @media screen and (max-width: 425px) {
          font-size: 1.2rem;
          line-height: 24px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      &:nth-child(3) {
        & > div {
          padding: 2px;
          border-radius: 5px;
          width: 100%;
          & input {
            flex: 1;
            padding: 2px 5px;
            border: none;
            outline: none;
            font-size: 1.2rem;
            border-radius: 5px;
            width: 80%;
          }
          & > div {
           
            cursor: pointer;
            border-radius: 5px;
            /* display: flex; */
            justify-content: center;
            align-items: center;
            overflow: hidden;
          }
        }
      }
      & > div {
        display: flex;
        & div {
          padding: 10px 20px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 60%;
            background-color: white;
          }
          &:last-child::after {
            display: none;
          }
        }
      }
    }
  }
  & > p {
    padding: 1rem 0;
    text-align: center;
  }
`;
export const ReachUs =styled('span')`
  .flex{
    display: flex;
    /* margin-top: 15px; */
    align-items: center;
    margin-top: 13px;
  }
  p{
    padding-bottom: 0 !important;
    
  }
`