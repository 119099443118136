import React, {useState} from "react";
import { Link } from "react-router-dom";
import {FaEye, FaEyeSlash } from "react-icons/fa";

const SignUp = () => {
  const[mobile, setMobile] = useState('');
  const[email, setEmail] = useState('');
  const[password, setPassword] = useState('');
  const[cPassword, setCPassword] = useState('');

  const[show1, setShow1] = useState(false);
  const[show2, setShow2] = useState(false);

  const showPassword = (id: number) => {
    if(id ===1){
      setShow1(!show1);
    }
    else{
      setShow2(!show2);
    }
  }

  

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
  }

  return (
    <>
      <p className="text-xl font-medium text-center text-black">Create an account </p>
      <p className="text-sm text-center md:hidden my-2">
        Already a User ?{" "}
        <Link to="/auth/login" className="text-blue-600 no-underline font-bold">Log In</Link>
      </p>

      <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-0 mt-2">
        <input required
          className="w-full p-2 border-b-2 border-gray-300 outline-none text-md"
          type="text"
          name="mobile"
          id="mobile"
          value={mobile}
          placeholder="Enter Mobile"
          onChange={(e) =>setMobile(e.target.value)}
        />

        <input required
          className="w-full p-2 border-b-2 border-gray-300 outline-none text-md"
          type="email"
          placeholder="Enter Mail"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="w-full border-b-2 border-gray-300 flex flex-row">
          <input required
            className="w-9/10 p-2 outline-none text-md"
            type={`${show1 ? "text" : "password"}`}
            placeholder="Enter Password"
            name="password"
            id="password"
            value={password}
            onChange={(e) =>setPassword(e.target.value)}
          />
          <button type="button" onClick={()=>showPassword(1)}
            className="w-1/10 flex items-center justify-center outline focus:outline-none">
            <p className="text-gray-500">{show1 ? <FaEyeSlash /> : <FaEye />}</p>
          </button>
        </div>

        <div className="w-full border-b-2 border-gray-300 flex flex-row">
          <input required
            className="w-9/10 p-2 outline-none text-md"
            type={`${show2 ? "text" : "password"}`}
            placeholder="Confirm Password"
            name="cPassword"
            id="cPassword"
            value={cPassword}
            onChange={(e) =>setCPassword(e.target.value)}
          />
          <button type="button" onClick={()=>showPassword(2)}
            className="w-1/10 flex items-center justify-center outline focus:outline-none">
            <p className="text-gray-500">{show2 ? <FaEyeSlash /> : <FaEye />}</p>
          </button>
        </div>

        <div className="flex flex-row items-center gap-4">
          <input type="checkbox" required/>
          <label className="text-xs text-gray-500">
            I hereby confirm that I am above 18 years old
          </label>
        </div>
        <button type="submit"
           className="w-1/2 p-2 text-white bg-blue-600 hover:bg-blue-800 rounded-sm">
          verify OTP
        </button>
        <p className="text-xs text-gray-500">
          By creating this account , you agree to the{" "}
          <Link to="/privacy-policy" className="text-blue-400">Privacy Policy</Link> and
          <Link to="/terms-and-conditions" className="text-indigo-400"> Terms of Use</Link>
        </p>
      </form>
    </>
  );
};

export default SignUp;
