//import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as CompanyLogo } from "../../assets/companyLogo.svg";
// import { ReactComponent as BrandIcon } from "../../assets/brandIcon.svg";

const AuthNavbar = () => {
    const history = useHistory();
    const location = useLocation();

    const signupbtn = () =>{
        history.push("/auth/signup");
    }
    const loginbtn = () =>{
        history.push("/auth/login");
    }
    return (
        <>
        <div className="flex items-center justify-between align-center pt-8 text-white">
            <Link to="/" className="z-10 flex flex-row gap-1 items-center">
            {/* <BrandIcon className="mb-3"/> */}
            <CompanyLogo className="h-12 -ml-3" />
            </Link>
            <div className="items-center justify-between hidden gap-8 md:flex">
            {location.pathname === "/auth/signup" ?
                <>
                    <p>Already a User ?</p>
                    <button className="text-sm text-white bg-blue-600 hover:bg-blue-800 rounded-sm w-auto py-2 px-5" onClick={loginbtn}>
                        Log In
                    </button>
                </>
                :
                <>
                    <p>New to Subspace ?</p>
                    <button className="text-sm text-white bg-blue-600 hover:bg-blue-800 rounded-sm w-auto py-2 px-5" onClick={signupbtn}>
                        Sign Up
                    </button>
                </> }
            </div>
        </div>
        </>
    );
};

export default AuthNavbar;
