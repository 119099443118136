import React, {useState} from 'react';

interface propsType {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const BusinessOverviewForm = ({setShowModal}: propsType) => {
    const[bType, setBType] = useState('Select');
    const[bCategory, setBCategory] = useState('');
    const[bDescription, setBDescription] = useState('');

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(bType,bCategory,bDescription);
    }

    return (
        <>
            <div className="uppercase flex flex-row justify-between items-center">
                <p className="text-xl font-medium uppercase">Business Overview</p>
                <button className="text-black text-xl font-bold outline-none focus:outline-none hidden md:block"
                    onClick={() => setShowModal(false)}> x </button>
            </div>
            <div className="my-8 md:my-16 text-sm md:px-8">
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center">
                        <label htmlFor="bType" className="w-full md:w-1/3">Business Type<span className="text-red-500">*</span></label>
                        <select
                            className="bg-white outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            id="bType" 
                            name="bType"
                            value={bType}
                            onChange={(e)=> setBType(e.target.value)}>
                                <option value="Service">Service</option>
                                <option value="Product">Product</option>
                                <option value="Both">Both</option>
                        </select>
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center">
                        <label htmlFor="bCategory" className="w-full md:w-1/3">Business Category<span className="text-red-500">*</span></label>
                        <select
                            className="bg-white outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            id="bCategory" 
                            name="bCategory"
                            value={bCategory}
                            onChange={(e)=> setBCategory(e.target.value)}>
                                <option value="Bills">Bills</option>
                                <option value="Books">Books</option>
                                <option value="Careers">Careers</option>
                                <option value="Communications">Communications</option>
                                <option value="Courses">Courses</option>
                                <option value="Dating">Dating</option>
                                <option value="eCommerce">eCommerce</option>
                                <option value="Education">Education</option>
                                <option value="Finance">Finance</option>
                        </select>
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-start mb-2">
                        <label htmlFor="bDescription" className="w-full md:w-1/3">Business Description<span className="text-red-500">*</span></label>
                        <div className="w-full md:w-2/3">
                            <textarea required
                                rows={5}
                                className="outline-none focus:outline-none py-2 px-4 border-2 w-full border-gray-300 rounded-lg"
                                id="bDescription" 
                                name="bDescription"
                                placeholder="Minimum 50 characters"
                                value={bDescription}
                                onChange={(e)=> setBDescription(e.target.value)} />

                            <p className="w-full my-2 text-xs">
                                Please give a brief description of the nature of your business. 
                                Please include examples of products you sell, the business category 
                                you operate under, your customers and the channels you primarily 
                                use to conduct your business(Website, offline retail etc).
                            </p>
                        </div>
                    </div>
                    <div className="absolute bottom-0 left-0 w-full border-t border-gray-400 p-3 min-h-50 flex flex-row justify-end items-center gap-4">
                        <button type="submit" className="border border-black text-black text-sm py-2 px-3 rounded-lg">
                            Save
                        </button>
                        <button type="submit" className="bg-black text-white text-sm py-2 px-3 rounded-lg">
                            Save & Next
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default BusinessOverviewForm;
