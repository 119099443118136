import React from 'react';

const Subscription = ()=> {
    return (
        <div className="text-blue-900">
            Subscription
        </div>
    )
}

export default Subscription;