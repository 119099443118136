import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
  }
`;
export const ContentContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 5rem 0;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
  & > div:first-child {
    margin: 0 auto 3rem;
    text-align: center;
    color: white;
  }
  & > div:nth-child(3) {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
    & > div:nth-child(1),
    & > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem 0;
      @media screen and (max-width: 1024px) {
        width: 30%;
      }
      @media screen and (max-width: 425px) {
        width: 100%;
        & > div:first-child {
          margin-bottom: 2rem;
        }
      }
    }
    & > div:nth-child(2) {
      /* margin-left: 40px; */
      max-width: 40%;
      padding: 1rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 425px) {
        max-width: 100%;
      }
      & > img {
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }
  }
`;
export const FeaturesCard = styled.div<{ pos: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) =>
    props.pos === "center"
      ? "center"
      : props.pos === "right"
      ? "flex-end"
      : "flex-start"};
  color: white;
  @media screen and (max-width: 425px) {
    align-items: center;
  }
  & > svg {
    margin-bottom: 1.5rem;
  }
  & h3 {
    font-size: 1.5rem;
    line-height: 30px;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
    text-align: ${(props) => props.pos};
    @media screen and (max-width: 425px) {
      text-align: center;
    }
  }
  & > p {
    width: 300px;
    text-align: ${(props) => props.pos};
    font-size: 1.2rem;
    line-height: 24px;
    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 40px;
      overflow: hidden;
    }
    @media screen and (max-width: 425px) {
      text-align: center;
    }
  }
`;
