import styled from "styled-components";

export const SectionTitle = styled.h2<{ isBlack: boolean }>`
  text-align: center;
  color: ${(props) => (props.isBlack ? "#232233" : "white")};
  font-size: 2rem;
  margin: 0 auto 20px;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-width: 425px) {
    width: 90%;
    font-size: 1.8rem;
    line-height: 35px;
  }
`;

export const SectionSubTitle = styled.p<{ isBlack: boolean }>`
  text-align: center;
  width: 40%;
  margin: 0 auto;
  color: ${(props) => (props.isBlack ? "#6c6c72" : "white")};
  line-height: 24px;
  @media screen and (max-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-width: 425px) {
    width: 90%;
    height: 72px;
    overflow: hidden;
  }
`;
