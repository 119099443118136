import React from "react";
import AppFeaturesbg from "../../../../assets/AppFeaturesbg.png";
import TutorialVideoImage from "../../../../assets/TutorialVideoImage.png";
import { ReactComponent as VideoPlayIcon } from "../../../../assets/VideoPlayIcon.svg";
import {
  SectionSubTitle,
  SectionTitle,
} from "../commonElements/index.Elements";
import {
  Container,
  ContentContainer,
  ImageContainer,
} from "./section6.Elements";

const Section6 = () => {
  return (
    <Container>
      <img src={AppFeaturesbg} alt="App Fretures background" />
      <ContentContainer>
        <div>
          <SectionTitle isBlack={false}>
            how to use the app perfectly
          </SectionTitle>
          <SectionSubTitle isBlack={false}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
            nunc ante velit vitae. Est tellus vitae, nullam lobortis enim.
            Faucibus amet etiam tincidunt rhoncus, ullamcorper velit.
            Ullamcorper risus tempor, ac nunc libero urna, feugiat.
          </SectionSubTitle>
        </div>
        <ImageContainer>
          <img src={TutorialVideoImage} alt="Thumbnail for tutorial video" />
          <VideoPlayIcon />
        </ImageContainer>
      </ContentContainer>
      <div></div>
    </Container>
  );
};

export default Section6;
