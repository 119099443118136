import React from "react";
import { ReactComponent as BlueCheckMarkIcon } from "../../../../../assets/BlueCheckMarkIcon.svg";
import { Container } from "./aboutCard.Elements";

interface propsType {
  title: string;
  description: string;
}

const AboutCard = ({ title, description }: propsType) => {
  return (
    <Container>
      <BlueCheckMarkIcon />
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </Container>
  );
};

export default AboutCard;
