import React, {useState} from 'react';

interface propsType {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const BusinessDetailsForm = ({setShowModal}: propsType) => {
    const[pan, setPan] = useState('');
    const[name, setName] = useState('');
    const[billing, setBilling] = useState('');
    const[address, setAddress] = useState('');
    const[pincode, setPincode] = useState('');
    const[city, setCity] = useState('');
    const[state, setState] = useState('');

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(pan,name,billing,address,pincode,city,state);
    }

    return (
        <>
            <div className="uppercase flex flex-row justify-between items-center ">
                <p className="text-xl font-medium uppercase">Business Details</p>
                <button className="text-black text-xl font-bold outline-none focus:outline-none hidden md:block"
                    onClick={() => setShowModal(false)}> x </button>
            </div>
            <div className="my-8 text-sm md:px-8">
                <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center">
                        <label htmlFor="pan" className="w-full md:w-1/3">PAN<span className="text-red-500">*</span></label>
                        <input required
                            className="outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            type="text" 
                            id="pan" 
                            name="pan"
                            value={pan}
                            onChange={(e)=> setPan(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-start">
                        <label htmlFor="name" className="w-full md:w-1/3">PAN Holder’s Name<span className="text-red-500">*</span></label>
                        <div className="w-full md:w-2/3">
                            <input required
                                className="outline-none focus:outline-none py-2 px-4 border-2 w-full border-gray-300 rounded-lg"
                                type="text" 
                                id="name" 
                                name="name"
                                value={name}
                                onChange={(e)=> setName(e.target.value)} />
                            <p className="w-full my-2 text-xs">
                                We verify the details with the central PAN database. 
                                Please ensure you enter the correct details.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center">
                        <label htmlFor="billing" className="w-full md:w-1/3">Billing Label<span className="text-red-500">*</span></label>
                        <input required
                            className="outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            type="text"
                            id="billing" 
                            name="billing"
                            value={billing}
                            onChange={(e)=> setBilling(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center mb-2">
                        <label htmlFor="address" className="w-full md:w-1/3">Address<span className="text-red-500">*</span></label>
                        <textarea required
                            rows={3}
                            className="outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            id="address" 
                            name="address"
                            placeholder="Enter Street Address"
                            value={address}
                            onChange={(e)=> setAddress(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center mb-2">
                        <label htmlFor="address" className="w-full md:w-1/3">Pin Code<span className="text-red-500">*</span></label>
                        <input required
                            className="outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            id="pincode" 
                            type="number"
                            name="pincode"
                            value={pincode}
                            onChange={(e)=> setPincode(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center mb-2">
                        <label htmlFor="city" className="w-full md:w-1/3">City<span className="text-red-500">*</span></label>
                        <input required
                            className="outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            id="city" 
                            type="text"
                            name="city"
                            value={city}
                            onChange={(e)=> setCity(e.target.value)} />
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-4 justify-start items-center mb-2">
                        <label htmlFor="address" className="w-full md:w-1/3">State<span className="text-red-500">*</span></label>
                        <input required
                            className="outline-none focus:outline-none py-2 px-4 border-2 w-full md:w-2/3 border-gray-300 rounded-lg"
                            id="state" 
                            type="text"
                            name="state"
                            value={state}
                            onChange={(e)=> setState(e.target.value)} />
                    </div>
                    <div className="absolute bottom-0 left-0 w-full border-t border-gray-400 p-3 min-h-50 flex flex-col md:flex-row justify-end items-center gap-4">
                        <div className="flex flex-row items-center gap-1">
                            <input type="checkbox" required/>
                            <label className="text-sm text-gray-500">
                                I agree to Razorpay Terms and Conditions
                            </label>
                        </div>
                        
                        <button type="submit" className="bg-black text-white text-sm py-2 px-3 rounded-lg">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default BusinessDetailsForm;
